import { mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'

export const NoResultsFoundContainer = styled.div`
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: 0 auto;
  padding: ${spacing(3)} ${spacing(2)} 0;
  align-items: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${spacing(3)} ${spacing(4)} 0;
  }
`

export const Title = styled.h1`
  ${Text('xlarge2', 'alternate')};
  margin: ${spacing(1)} 0 ${spacing(2)} 0;
`

export const Subtitle = styled.p`
  ${Text('bodyText', 'alternate')};
  margin: ${spacing(2)} 0;
  padding-bottom: ${spacing(2)}; ;
`
