import * as React from 'react'

import { i18n } from '@thg-commerce/enterprise-core'
import {
  Maybe,
  Widget,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

import { NoResultsFoundContainer, Subtitle, Title } from './styles'

export const showNoResultsPLP = (
  productList: any,
  widgets: Maybe<Widget[]> | undefined,
  enableNoResultsPLP?: boolean,
) => !productList && !widgets?.length && enableNoResultsPLP

export const ListNoResultsFound = () => {
  const i18nText = {
    title: i18n('content.listresults.noresultsfound.title'),
    subtitle: i18n('content.listresults.noresultsfound.subtitle'),
  }

  return (
    <NoResultsFoundContainer>
      <Title data-testid="no-list-results-found-title">{i18nText.title}</Title>
      <Subtitle> {i18nText.subtitle}</Subtitle>
    </NoResultsFoundContainer>
  )
}
