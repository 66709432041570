import * as React from 'react'
import { useState } from 'react'
import withHydrationOnDemand from 'react-hydration-on-demand'
import Media from 'react-media'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { GetServerSidePropsContext } from 'next'
import { useRouter } from 'next/compat/router'
import getConfig from 'next/config'
import Head from 'next/head'

import { ProductBlock } from '@thg-commerce/enterprise-aurora-landing/aurora-schema'
import { PageArgs } from '@thg-commerce/enterprise-aurora-landing/resolvers/Page/resolver'
import {
  generateObjectCacheKey,
  ObjectCacheKey,
  pageTTLConfig,
  PageType as CachePageType,
} from '@thg-commerce/enterprise-cache'
import { BreadcrumbsRenderer } from '@thg-commerce/enterprise-components'
import { SponsoredAdsType } from '@thg-commerce/enterprise-components/SponsoredAds/queryBuilders'
import PowerReviewHead from '@thg-commerce/enterprise-components/src/PowerReview/PowerReviewHead'
import {
  EnterpriseNextPageContext,
  getIndexableFacetCanonicalUrl,
  getIndexableFacetValue,
  LIST_EXTENSION,
  loggerProvider,
  PageWrapper,
  Routes,
  useExperiments,
  useI18n,
  useSessionSettings,
  useSiteConfig,
  useSiteDefinition,
  useTheme,
  withCacheConfiguration,
} from '@thg-commerce/enterprise-core'
import {
  EnterpriseNextPage,
  PageType,
} from '@thg-commerce/enterprise-core/src/App/types'
import { PageCacheConfiguration } from '@thg-commerce/enterprise-core/src/cache/configuration'
import { loadConfiguration } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/ConfigurationLoader'
import { NextConfig } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { createApolloWithRequest } from '@thg-commerce/enterprise-core/src/Factory/apollo'
import { createGraphQLClient } from '@thg-commerce/enterprise-core/src/Factory/graphql'
import { FullHeaderLayout } from '@thg-commerce/enterprise-core/src/Layouts'
import {
  EnterpriseRequest,
  EnterpriseResponse,
} from '@thg-commerce/enterprise-core/src/Server/types'
import { getHistogram } from '@thg-commerce/enterprise-metrics/src/prometheus/histogram'
import { ComponentName } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/ComponentWidgets'
import { DataLayerProducts } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Page/ProductListProducts'
import { Page as QueryData } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  Country,
  Currency,
  FacetInput,
  Feature,
  Maybe,
  Page,
  PageAlternateLink,
  ProductSort,
  Widget,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { LandingPageContextProvider } from '@thg-commerce/enterprise-pages'
import { SelectedOptions } from '@thg-commerce/enterprise-product-options/src'
import { productOptionsHeaderOverrides } from '@thg-commerce/enterprise-product-options/src/headerOverrides'
import { mq, spacing, styled } from '@thg-commerce/enterprise-theme'
import { isClickAndCollectEnabled } from '@thg-commerce/enterprise-utils/src/ClickAndCollect/ClickAndCollect'
import { ProductCategoryItemProps } from '@thg-commerce/enterprise-widget-product-categories'
import {
  ChangeType,
  PageOptions,
  ProductListPage,
} from '@thg-commerce/enterprise-widget-product-list-page'
import { getFacetUrlFromInput } from '@thg-commerce/enterprise-widget-product-list-page/src/Facets/utils'
import { PageOptionsParams } from '@thg-commerce/enterprise-widget-product-list-page/src/ProductListPage'
import {
  ComponentWidgetRenderer,
  WidgetRenderer,
} from '@thg-commerce/enterprise-widgets'
import { getFacetInputFromUrl } from '@thg-commerce/enterprise-widgets/src/ProductListWidgetRenderer/utils'
import { GridItem } from '@thg-commerce/gravity-system'
import { ThemeInterface } from '@thg-commerce/gravity-theme'

import { GlobalComponentWidgets as GLOBAL_COMPONENT_QUERY_AURORA } from '../../graphql/GlobalComponentWidgets.graphql'
import { Page as AURORA_PAGE_QUERY } from '../../graphql/Page.graphql'
import { ConcessionCode as CONCESSION_CODE_QUERY } from '../../src/graphql/ConcessionCode.graphql'
import { checkForPrefixedPageMatch } from '../../src/helpers/page'
import {
  breadcrumbs,
  breadcrumbsSchema,
  getTagKeys,
  sortOrderOptions,
  sortTypesOptions,
} from '../../src/landingUtils'
import {
  ListNoResultsFound,
  showNoResultsPLP,
} from '../../src/renderer/ListNoResultsFound/ListNoResultsFound'
import { getPathAndParamsFromReq } from '../../src/utils'
import CustomError from '../_error'

// @TODO: [CSBOM-325] Pull these values from content keys
const IFP_FILTERS = [
  'en_brand_content',
  'en_hbg_sizeFacet_content',
  'en_hbg_baseColour_content',
  'en_hbg_type_content',
]

const GlobalWidgetRendererComponent = withHydrationOnDemand({
  on: [['delay', 500]],
})(WidgetRenderer)

type PageData = QueryData & {
  categories: string[]
  varyHeaders: string[]
  productListWidget?: any
  noIndex: boolean
  noFollow: boolean
  rayId: string
}

export interface LandingPageProps {
  path: string
  page?: PageData
  enableRefreshOnMobileFacets?: boolean
  componentWidgets?: Maybe<Widget[]>
  productList?: any
  dataLayerMap?: {
    [key: string]: {
      [key: string]: {
        propertyKey: string
        label: string
      }
    }
  }
  dataLayerProducts?: DataLayerProducts
  pageParams: {
    pageNumber?: string
    facetFilters?: string
    sortOrder?: string
  }
  statusCode: number
  showBackwardNavigationForBreadcrumbs: boolean
  wishlistEnabled?: boolean
  theme?: ThemeInterface
  pageType?: PageType
  productContentKeys?: string[]
  experiments?: { [key: string]: string }
}

const createRobotsContent = (noIndex: boolean, noFollow: boolean) => {
  return `${noIndex ? 'noindex' : 'index'}, ${noFollow ? 'nofollow' : 'follow'}`
}

export const BreadcrumbsRendererWrapper = styled(BreadcrumbsRenderer)`
  max-width: ${(props) => props.theme.site.siteWidth};
  padding-bottom: ${spacing(2)};
`

const Landing: EnterpriseNextPage<LandingPageProps> = (
  props: LandingPageProps,
) => {
  const i18n = useI18n()

  const i18nText = {
    metaTagKeywords: i18n('meta.keywords.end.text'),
    siteTitleEnd: i18n('titles.end.text'),
    defaultMetaDescriptionPart1: i18n('meta.description.list.default.1'),
    defaultMetaDescriptionPart2: i18n('meta.description.list.default.2'),
    defaultMetaDescriptionPart3: i18n('meta.description.list.default.3'),
  }
  const router = useRouter()

  const [loading, setLoading] = useState(false)

  const theme = useTheme()
  const { siteName, originUrl } = useSiteDefinition()
  const {
    enableIndexableFacetsPagination,
    enableEllipsisBreadCrumbsPLP,
    enableCanonicalIndexableFacetsTag,
    enableNoResultsPLP,
    hideBreadcrumbsOnLandingPage,
    enableFullBreadcrumbsOnMobile,
  } = useSiteConfig()
  const sessionSettings = useSessionSettings()

  const finalProductListProps = props.productList
  const experiments = useExperiments()

  React.useEffect(() => {
    if (loading) {
      setLoading(false)
    }
  }, [props.productList?.products, loading])

  if (!props.page || props.statusCode === 404) {
    return <CustomError statusCode={props.statusCode || 404} />
  }

  if (
    showNoResultsPLP(props.productList, props.page?.widgets, enableNoResultsPLP)
  ) {
    return (
      <React.Fragment>
        <ListNoResultsFound />
        <ComponentWidgetRenderer
          componentName={ComponentName.PAGE_UNAVAILABLE}
          currency={sessionSettings.currency}
          shippingDestination={sessionSettings.shippingDestination}
        />
      </React.Fragment>
    )
  }

  const sessionStorage = typeof window !== 'undefined' && window.sessionStorage

  const facetsInTitle =
    enableIndexableFacetsPagination && finalProductListProps
      ? finalProductListProps.pageOptions?.facets
          .filter(
            (facet) =>
              IFP_FILTERS.includes(facet.facetName) &&
              facet.selections.length === 1,
          )
          .map((facet) => facet.selections[0].optionName)
          .join(' ')
      : ''

  const generateCanonicalTag = () => {
    const baseUrl = `${originUrl}${
      props.page?.canonicalUrl ??
      (props.path !== '/' ? `${props.path}${LIST_EXTENSION}` : '')
    }${
      props.pageParams?.pageNumber && !enableCanonicalIndexableFacetsTag
        ? `?pageNumber=${props.pageParams.pageNumber}`
        : ''
    }`

    return enableCanonicalIndexableFacetsTag
      ? getIndexableFacetCanonicalUrl(baseUrl, {
          facetFilters: finalProductListProps?.pageOptions?.facets,
          pageNumber: finalProductListProps?.pageOptions?.pageNumber,
        })
      : baseUrl
  }

  const canonicalTag = generateCanonicalTag()

  const refetchDataWithGetSSP = (queryParams: string) => {
    setLoading(true)

    router.push(
      {
        pathname: '/landing/[...path]',
        query: `path=${props.path}&${queryParams}`,
      },
      {
        pathname: `${props.path}.list`,
        query: queryParams,
      },
      {
        scroll: false,
      },
    )
  }

  const handleFacetsChange = (url: URL, facets: FacetInput[]) => {
    url.searchParams.set(
      PageOptionsParams.FACET_FILTERS,
      getFacetUrlFromInput(facets),
    )
    url.searchParams.delete(PageOptionsParams.PAGE_NUMBER)

    if (enableIndexableFacetsPagination) {
      if (!facets.length && url.searchParams.has(PageOptionsParams.IFP)) {
        url.searchParams.delete(PageOptionsParams.IFP)
      } else {
        url.searchParams.set(
          'IFP',
          getIndexableFacetValue(newInput.facets).toString(),
        )
      }
    }

    refetchDataWithGetSSP(url.search.slice(1))
  }

  const pageTitle = facetsInTitle
    ? `${facetsInTitle} ${finalProductListProps?.title} | ${props.page?.title}`
    : props.page?.title

  const HeadSEO = ({ props, i18nText, canonicalTag, siteName, originUrl }) => {
    return (
      <Head>
        <title>{`${pageTitle} ${i18nText.siteTitleEnd}`}</title>
        {props.path === '/' && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                url: originUrl,
                logo: props.theme?.logo?.logoUri,
              }),
            }}
          ></script>
        )}
        <meta
          name="description"
          content={
            props.page?.metaDescription ||
            `${i18nText.defaultMetaDescriptionPart1} ${props.page?.title} ${i18nText.defaultMetaDescriptionPart2} ${i18nText.defaultMetaDescriptionPart3}`
          }
        />
        {
          <meta
            name="robots"
            content={createRobotsContent(
              props.page.noIndex,
              props.page.noFollow,
            )}
          />
        }
        <meta
          name="keywords"
          content={`${siteName}, ${i18nText.metaTagKeywords}`}
        />
        {props.page?.alternateLinks.map((link: PageAlternateLink) => (
          <link
            key={link.hreflang}
            rel="alternate"
            href={`https://${link.pagePath}${
              props.path !== '/' ? LIST_EXTENSION : ''
            }`}
            hrefLang={link.hreflang}
          />
        ))}

        {canonicalTag && (
          <link rel="canonical" href={canonicalTag} key="link_canonical" />
        )}

        {props.path === '/' && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'WebSite',
                url: originUrl,
                potentialAction: {
                  '@type': 'SearchAction',
                  target: {
                    '@type': 'EntryPoint',
                    urlTemplate: `${originUrl}/elysium.search?search={search_term_string}`,
                  },
                  'query-input': 'required name=search_term_string',
                },
              }),
            }}
          />
        )}
      </Head>
    )
  }

  return (
    <React.Fragment>
      <HeadSEO
        props={props}
        i18nText={i18nText}
        canonicalTag={canonicalTag}
        siteName={siteName}
        originUrl={originUrl}
      />
      <PowerReviewHead />
      <LandingPageContextProvider categories={props.page.categories}>
        <PageWrapper
          enableMaxWidth={false}
          compactMargin={true}
          sendPageVisit={true}
        >
          <GridItem colSpan={12}>
            {props.path !== '/' && !(hideBreadcrumbsOnLandingPage ?? false) && (
              <Media query={mq(theme.breakpointUtils.map, 'sm', true)}>
                {(isDesktop) => (
                  <BreadcrumbsRendererWrapper
                    breadcrumbs={breadcrumbs(props.page?.breadcrumbs)}
                    schemaItems={breadcrumbsSchema(
                      breadcrumbs(props.page?.breadcrumbs),
                      originUrl,
                    )}
                    enableBackButton={
                      props.showBackwardNavigationForBreadcrumbs
                    }
                    originUrl={originUrl}
                    {...(enableEllipsisBreadCrumbsPLP && {
                      ellipsis: {
                        enabled: Boolean(enableEllipsisBreadCrumbsPLP),
                        minNumberOfItems: 3,
                        hideActiveLink: !isDesktop,
                      },
                      isMobile: !isDesktop,
                    })}
                    forceDisplayBreadcrumbs={
                      enableFullBreadcrumbsOnMobile ?? false
                    }
                  />
                )}
              </Media>
            )}
            <WidgetRenderer
              widgets={props.page.widgets || []}
              path={props.path}
              pageParams={props.pageParams}
            />
            {finalProductListProps && (
              <ProductListPage
                path={props.path}
                sponsoredAdsType={SponsoredAdsType.PLP}
                triggerCallbackOnSave={!props.enableRefreshOnMobileFacets}
                dataLayerProducts={props.dataLayerProducts}
                setSingleColumn={(item, value) =>
                  sessionStorage && sessionStorage.setItem(item, value)
                }
                products={finalProductListProps.products}
                itemsPerPage={finalProductListProps.itemsPerPage}
                title={finalProductListProps.title}
                description={finalProductListProps.description}
                seoDescription={finalProductListProps.seoDescription}
                changeType={finalProductListProps.changeType}
                categories={finalProductListProps.categories}
                categoriesTitle={finalProductListProps.categoriesTitle}
                facets={finalProductListProps.facets}
                pageOptions={finalProductListProps.pageOptions}
                totalItems={finalProductListProps.totalItems}
                loading={loading}
                facetsInTitle={facetsInTitle}
                sortTypes={sortTypesOptions(experiments, i18n)}
                onInputChange={(newInput: PageOptions) => {
                  const url = new URL(window.location.href)
                  if (newInput.pageNumber) {
                    newInput.pageNumber > 1
                      ? url.searchParams.set(
                          PageOptionsParams.PAGE_NUMBER,
                          newInput.pageNumber.toString(),
                        )
                      : url.searchParams.delete(PageOptionsParams.PAGE_NUMBER)
                  }

                  if (newInput.sort) {
                    url.searchParams.set(
                      PageOptionsParams.SORT_ORDER,
                      newInput.sort.toString(),
                    )
                    url.searchParams.delete(PageOptionsParams.PAGE_NUMBER)
                  }

                  if (!newInput.facets) {
                    if (props.enableDataRefetchWithGetSSP) {
                      refetchDataWithGetSSP(url.search.slice(1))
                      return
                    }

                    window.location.href = url.toString()
                    return
                  }

                  handleFacetsChange(url, newInput.facets)
                }}
                dataLayerMap={props.dataLayerMap || {}}
              />
            )}
          </GridItem>
        </PageWrapper>
        <GlobalWidgetRendererComponent
          widgets={props.componentWidgets || []}
          path={props.path}
          pageParams={props.pageParams}
        />
      </LandingPageContextProvider>
    </React.Fragment>
  )
}

Landing.Layout = FullHeaderLayout

Landing.supportsConcessions = true

Landing.pageType = (route, componentProps) => {
  if (route === Routes.HomePage) {
    return PageType.HOME_PAGE
  }

  if (componentProps.productList) {
    return PageType.PRODUCT_LIST
  }

  return PageType.LANDING
}

Landing.getConcessionCode = async (
  ctx: EnterpriseNextPageContext,
  apolloClient?: ApolloClient<NormalizedCacheObject>,
): Promise<string | undefined> => {
  const horizonFeatures = ctx.req.horizonFeatures || []

  if (!horizonFeatures?.includes(Feature.Concessions)) {
    return undefined
  }

  const concessionEnabled = horizonFeatures?.includes(Feature.Concessions)

  if (!concessionEnabled) {
    return undefined
  }

  const { path } = getPathAndParamsFromReq(ctx)

  if (!path || path === '') {
    return undefined
  }

  if (apolloClient) {
    const { data } = await apolloClient.query<{
      page: {
        concession: {
          concessionCode: string
        }
      }
    }>({
      query: CONCESSION_CODE_QUERY,
      variables: {
        pathName: path,
      },
    })

    if (data?.page?.concession?.concessionCode) {
      return data.page.concession.concessionCode
    }

    return undefined
  }

  return undefined
}

export const getServerSideProps = withCacheConfiguration<
  { props: LandingPageProps },
  GetServerSidePropsContext
>(
  async (
    context: GetServerSidePropsContext,
  ): Promise<{ props: LandingPageProps; cache?: PageCacheConfiguration }> => {
    const nextConfig = getConfig() as NextConfig
    const request = context.req as EnterpriseRequest
    const response = context.res as EnterpriseResponse
    const config = await loadConfiguration(nextConfig, request)

    const { publicRuntimeConfig, dataLayerMap = {}, contentKeys } = config
    const { brand, subsite, originUrl } = publicRuntimeConfig.siteDefinition
    const logger = loggerProvider({ brand, subsite, originUrl })
    const { siteDefinition, siteConfig } = publicRuntimeConfig

    const results = getPathAndParamsFromReq(context)

    const shippingDestination = (request.config.sessionSettings
      ?.shippingDestination ||
      siteDefinition.defaultSessionSettings.shippingDestination) as Country
    const currency = (request.config.sessionSettings?.currency ||
      siteDefinition.defaultSessionSettings.currency) as Currency

    const {
      productItemsPerPage,
      showBackwardNavigationForBreadcrumbs = false,
      enableWishlists,
      enableWishlistsGlobal,
      hideProductListWishlists,
      hasImagesCarouselOnMobilePLP,
      enableHeroProductImageTag,
      productTagsKeys,
      enableProductPersonalisation = false,
      enablePricePerUnit = false,
      alternateProductKeysForCategories,
      enableClickAndCollect = false,
    } = siteConfig

    const horizonFeatures = request.horizonFeatures
    const clickAndCollectEnabled = isClickAndCollectEnabled(
      horizonFeatures?.includes(Feature.ClickAndCollect),
      enableClickAndCollect,
    )
    const vipPriceEnabled =
      (siteConfig.enableVipPrice ?? false) &&
      (request.horizonFeatures || []).includes(Feature.VipPricingEnabled)
    const wishlistEnabled =
      (enableWishlists &&
        enableWishlistsGlobal &&
        !hideProductListWishlists &&
        horizonFeatures?.includes(Feature.Wishlist)) ||
      false

    const imageLimit = hasImagesCarouselOnMobilePLP ? 10 : 2

    const itemsPerPage = productItemsPerPage || 45
    const selectedPageNumber =
      results.pageParams?.pageNumber &&
      parseInt(results.pageParams?.pageNumber, 10) >= 1
        ? parseInt(results.pageParams?.pageNumber, 10)
        : 1

    let path =
      results.path === ''
        ? '/'
        : new URL(
            decodeURIComponent(results.path),
            siteDefinition.originUrl,
          ).pathname
            .replace(/\./g, '')
            .replace(/^\/perf\//, '') // @TODO: Improve handling of URL's that contain dots - Horizon throws an exception

    const invalidPath = results.path !== '' && /[./]$/.test(results.path)
    const client = createGraphQLClient(config, request)

    const queryStartTime = new Date()
    let queryEndTime
    try {
      const { preselectVariant } = productOptionsHeaderOverrides({
        siteConfig,
        headers: request.headers,
      })

      const enableRefreshOnMobileFacetsHeader =
        request.headers['x-enterprise-refresh-on-mobile-facet-change'] === '1'

      const enableRefreshOnMobileFacets = Boolean(
        enableRefreshOnMobileFacetsHeader ||
          siteConfig.refreshOnMobileFacetChange,
      )

      const productContentKeys = [
        ...(contentKeys?.productBlock || []),
        ...(alternateProductKeysForCategories || []),
      ]

      const tagKeys = getTagKeys(productContentKeys, productTagsKeys)

      const { data } = await client.query<
        {
          page: PageData
          componentWidgets: Maybe<Widget[]>
          productListProducts?: { page: Page }
          rayId: string
        },
        PageArgs
      >(AURORA_PAGE_QUERY, {
        path,
        wishlistEnabled,
        currency,
        shippingDestination,
        enablePricePerUnit,
        preselectVariant,
        enableProductPersonalisation,
        vipPriceEnabled,
        productContentKeys,
        tagKeys,
        clickAndCollectEnabled: clickAndCollectEnabled ?? false,
        enableHeroProductImageTag: enableHeroProductImageTag || false,
        limitImages: imageLimit,
        productListInput: {
          currency,
          shippingDestination,
          limit: itemsPerPage,
          offset:
            selectedPageNumber > 1
              ? itemsPerPage * (selectedPageNumber - 1)
              : 0,
          sort: sortOrderOptions(
            results?.pageParams?.sortOrder as ProductSort,
            request.extensions?.experiments,
          ),
          facets: getFacetInputFromUrl(
            results.pageParams.facetFilters
              ? decodeURIComponent(results.pageParams.facetFilters)
              : '',
          ),
        },
        context: {
          currency,
          shippingDestination,
          clickAndCollectEnabled,
          pagePath: path,
        },
      })

      queryEndTime = new Date().getTime() - queryStartTime.getTime()

      if (data?.page) {
        const productList = data.page.productListWidget
        const topProductCategorySet = productList?.topProductCategorySet

        const productCategoryItems: ProductCategoryItemProps[] =
          topProductCategorySet?.banners
            ?.filter(
              (item) =>
                item.topProductCategoryName && item.topProductCategoryUrl,
            )
            .map((item) => {
              return {
                text: item.topProductCategoryName as string,
                href: item.topProductCategoryUrl as string,
              }
            }) || []

        const { data: auroraComponentWidgets } = await client.query<any>(
          GLOBAL_COMPONENT_QUERY_AURORA,
        )
        const cachePageType =
          path === '/' ? CachePageType.HOME : CachePageType.LANDING
        const cacheTTLConfig = pageTTLConfig(cachePageType) || {
          ttl: 300,
          grace: 86400,
        }

        const products = getProducts({
          productBlocks: productList?.productBlockList.productBlocks,
        })

        const props =
          productList && productList.productBlockList.productBlocks.length > 0
            ? {
                ...results,
                dataLayerMap,
                productContentKeys,
                rayId: data.page.rayId || null,
                wishlistEnabled,
                path,
                enableRefreshOnMobileFacets,
                showBackwardNavigationForBreadcrumbs,
                componentWidgets:
                  auroraComponentWidgets?.globalComponentWidgets || null,
                page: data.page,
                pageType: PageType.PRODUCT_LIST,
                dataLayerProducts: productList.dataLayerProducts,
                productList: {
                  // TODO: conditionally merge when products exist
                  products,
                  itemsPerPage,
                  title: productList.title || '',
                  description: productList.description || '',
                  seoDescription: productList.seoDescription || '',
                  changeType: ChangeType.NONE,
                  categories: productCategoryItems,
                  categoriesTitle: topProductCategorySet?.DiscoverTitle || null,
                  facets: productList.productBlockList?.facets,
                  pageOptions: {
                    sort: sortOrderOptions(
                      results?.pageParams?.sortOrder as ProductSort,
                      request.extensions?.experiments,
                    ),
                    facets: getFacetInputFromUrl(
                      results.pageParams.facetFilters
                        ? decodeURIComponent(results.pageParams.facetFilters)
                        : '',
                    ),
                    pageNumber: results.pageParams.pageNumber
                      ? parseInt(results.pageParams.pageNumber, 10)
                      : 1,
                  },
                  totalItems: productList?.productBlockList?.total || 0,
                  statusCode: invalidPath ? 404 : 200,
                },
                statusCode: invalidPath ? 404 : 200,
              }
            : {
                ...results,
                productContentKeys,
                wishlistEnabled,
                enableRefreshOnMobileFacets,
                path,
                showBackwardNavigationForBreadcrumbs,
                rayId: data.page.rayId || null,
                componentWidgets:
                  auroraComponentWidgets?.globalComponentWidgets || null,
                page: data.page,
                pageType: path === '/' ? PageType.HOME_PAGE : PageType.LANDING,
                statusCode: invalidPath ? 404 : 200,
              }
        return {
          props,
          cache: {
            ...cacheTTLConfig,
            vary:
              data.page.varyHeaders.concat(
                data.productListProducts?.page.varyHeaders || [],
              ) || [],
            tags: [generateObjectCacheKey(ObjectCacheKey.PAGE, data.page.path)],
          },
        }
      }
    } catch (e) {
      const error = e as Error
      logger.warn(`Failed to load list page with error ${error.message}`, {
        stack: error.stack,
      })
    } finally {
      try {
        const histogram = getHistogram({
          name: 'enterprise_monitor_api_timings',
          labels: ['appname', 'brand', 'subsite', 'endpoint'],
          help: 'This metric stores the duration of API calls',
        })
        histogram
          .labels('content', brand, subsite, 'content_page_query')
          .observe(queryEndTime)
      } catch (error) {
        console.warn(`failed to raise metric for page query: ${error}`)
      }
    }

    if (response) {
      const apollo = createApolloWithRequest(request, config, true)
      const prefixedPageMatch = await checkForPrefixedPageMatch(path, apollo)
      if (prefixedPageMatch) {
        const redirectURL = new URL(
          `${prefixedPageMatch}.list`,
          `https://${siteDefinition.domain}`,
        )
        Object.entries(request.query).forEach(([key, value]) => {
          if (typeof value === 'string') {
            const decodedValue = decodeURIComponent(value)
            redirectURL.searchParams.set(key, decodedValue)
          }
        })

        // Support proper UTF encoding for consistency
        redirectURL.search = redirectURL.searchParams
          .toString()
          .replace(/\+/g, '%20')

        response.redirect(301, redirectURL.toString())
      } else {
        response.statusCode = 404
      }
    }
    const sortOrder = sortOrderOptions(
      results?.pageParams?.sortOrder as ProductSort,
      request.extensions?.experiments,
    )

    return {
      props: {
        path,
        showBackwardNavigationForBreadcrumbs,
        componentWidgets: [],
        pageParams: {
          ...(sortOrder && { sortOrder }),
        },
        statusCode: invalidPath ? 404 : response.statusCode || 404,
      },
    }
  },
)

const getProducts = ({
  productBlocks,
}: {
  productBlocks?:
    | (ProductBlock &
        {
          sortedOptionKeys: string[]
          selectedChoices: string[]
          selectedOptions: SelectedOptions
        }[])
    | null
}) =>
  productBlocks?.map((productBlock) => {
    const selectedOptions = productBlock.sortedOptionKeys.reduce(
      (accumulator, optionKey, index) => {
        const choiceKey = productBlock.selectedChoices[index]
        if (optionKey && choiceKey) {
          accumulator[optionKey] = choiceKey
        }

        return accumulator
      },
      {},
    )

    productBlock.selectedOptions = selectedOptions
    return productBlock
  })

export default Landing
